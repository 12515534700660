<template>
    <swiper
        @swiper="onSwiper"
        :slides-per-view="1"
        :allow-touch-move="false"
        :loop="true"
        :modules="modules" effect="fade"
        :pagination="{clickable: true}"
        :speed="1000"
        :autoplay="{
           delay: 4000
        }"
    >
        <swiper-slide v-for="(item, index) in items" :key="index">
            <div class="bg">
<!--                <img :src="item.image" alt="Image">-->
                <picture class="img-bg">
                    <source v-if="item.image_webp" :srcset="item.image_webp" type="image/webp">
                    <source :srcset="item.image">
                    <img class="img" :src="item.image" alt="Image">
                </picture>
                <div class="container">
                    <div class="col-lg-40 col-xl-24">
                        <h1>{{ item.title }}</h1>
                        <div class="content slider-top-editor" v-html="item.description"></div>

                        <a v-if="item.button_text" :href="item.button_link" class="hero-btn">{{ item.button_text }} <img src="/img/right-arrow.svg" alt="Right arrow"></a>
                    </div>
                </div>
            </div>
        </swiper-slide>
  </swiper>

    <div class="navigation d-flex justify-content-between">
        <button type="button" @click="slidePrev()"><img src="/img/hero-slider-nav.svg" class="prev" alt="Left arrow"></button>
        <button type="button" @click="slideNext()"><img src="/img/hero-slider-nav.svg" class="next" alt="Right arrow"></button>
    </div>


</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, EffectFade,Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';

export default {
    name: "HeroSlider",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        items: {
            type: Array,
            required: true
        },
    },

    setup() {
        return {
            modules: [Pagination,EffectFade,Autoplay]
        };
    },

    data() {
        console.log(this.items);
        return {
            items: this.items,
            swiper: null,
        };
    },

    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },

        slidePrev() {
            this.swiper.slidePrev();
        },
        slideNext() {
            this.swiper.slideNext();
        }

    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstracts/_variables.scss';
@import 'resources/sass/abstracts/_mixins.scss';
@import "bootstrap/scss/bootstrap-utilities";

.swiper {
    height: 634px;
}

.navigation {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 40px;
    button {
        opacity: .6;
        transition: all .2s ease-in-out;
        &:hover {
            opacity: 1;
            transform: scale(1.15);
        }
    }
    .next {
        transform: scaleX(-1);
    }
    @include media-breakpoint-down(lg) {
        top: calc(100% - 80px);
        padding: 0 20px;
    }
}

.bg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
    .container {
        position: relative;
        z-index: 2;
        @include media-breakpoint-up(lg) {
            padding: 0 90px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 40px 0 25px;
            margin-top: 12rem;

        }
    }
    h1 {
        color: $white;
        font-weight: 500;
       @include fluid-font($min-width,$max-width, 35px, 78px);
    }

    p {
        font-size: rem(18px);
        color: rgba($white,.6);
        /*margin-bottom: 40px;*/
    }
    .content {
        font-size: rem(18px);
        color: rgba($white,.6);
        margin-bottom: 40px;
    }

    .content >>> p {
        font-size: rem(18px);
        color: rgba($white,.6);
    }

    .hero-btn {
        display: inline-block;
        height: 58px;
        line-height: 58px;
        border: 1px solid rgba($white,.25);
        border-radius: 30px;
        text-transform: uppercase;
        padding: 0 30px;
        font-weight: 600;
        font-size: rem(14px);
        letter-spacing: 1.2px;
        img {
            margin-left: 10px;
            transition: all .2s ease-in-out;
        }
        &:hover,&:focus {
            color: $white;
            border-color: rgba($white,1);
            img {
                transform: translateX(10px);
            }
        }
    }
}
</style>

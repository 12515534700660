import "./components/gsap";
import "./components/toastify";
import "bootstrap/js/dist/dropdown";
import '@fortawesome/fontawesome-free/js/all';
import HeroSlider from "./vue-components/HeroSlider";

import BottomSlider from "./vue-components/BottomSlider";
import ProductDetailSlider from "./vue-components/ProductDetailSlider";
import PriceRangeSlider from './vue-components/PriceRangeSlider';
import OurTeamSlider from './vue-components/OurTeamSlider';

import {createApp} from "vue";
const app = createApp();

app.component('hero-slider', HeroSlider);
app.component('bottom-slider', BottomSlider);
app.component('product-detail-slider', ProductDetailSlider);
app.component('price-range-slider', PriceRangeSlider);
app.component('our-team-slider', OurTeamSlider);
app.mount('main');

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

/* HIDE HEADER ON SCROLL */
const header = document.querySelector('header');
let prevScrollPosition = scrollY;

window.addEventListener('scroll', function() {
    let currentScrollPosition = scrollY;

    if (scrollY > 0) {
        header.classList.add('smaller');
    } else {
        header.classList.remove('smaller');
    }
    if ((currentScrollPosition > 200) && (prevScrollPosition < currentScrollPosition)) {
        header.classList.add('hide');
    } else {
        header.classList.remove('hide');
    }
    prevScrollPosition = currentScrollPosition
});

/* SEARCH BAR OPENING */
const searchBtn = document.querySelector('.open-search');
const searchBox = document.querySelector('.search-box');
const searchInput = document.querySelector('.search-box input');

searchBtn.addEventListener('click', function() {
    searchBox.classList.toggle('hidden');
    searchInput.focus();
});

<template>
    <div class="slider-wrap">
        <swiper
            @swiper="onSwiper"
            :modules="modules"
            :slides-per-view="1"
            :space-between="67"
            :allow-touch-move="false"
            :speed="1000"
            :pagination="{
                el: '.progress-line'
            }"
        >
            <swiper-slide v-for="image in images">
<!--                <img :src="image" alt="Image">-->
                <picture class="img-bg">
                    <source v-if="image.path_webp" :srcset="image.path_webp" type="image/webp">
                    <source :srcset="image.path">
                    <img class="img" :src="image.path" alt="Image">
                </picture>
            </swiper-slide>
        </swiper>
    </div>

    <div class="navigation">
        <div class="container">
            <ul class="nav-btns d-flex list-unstyled gap-3 gap-md-5">
                <li><button type="button" @click="slidePrev"><img src="/img/arrow-bottom-slider.svg" alt="Arrow"></button></li>
                <li><button type="button" @click="slideNext"><img src="/img/arrow-bottom-slider.svg" alt="Arrow"></button></li>
            </ul>

            <div class="progress-line">

            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import {Pagination} from 'swiper';
import 'swiper/css';


export default {
    name: "BottomSlider",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        images: {
            type: Array,
            required: true
        },
    },

    setup() {
        return {
            modules: [Pagination]
        };
    },

    data() {
        return {
            images: this.images,
            swiper: null,
        };
    },

    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },

        slidePrev() {
            this.swiper.slidePrev();
        },
        slideNext() {
            this.swiper.slideNext();
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstracts/_variables.scss';
@import "bootstrap/scss/bootstrap-utilities";

.navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    button:hover {
        opacity: .5;
    }
    li:last-child {
        transform: scaleX(-1);
    }

    .progress-line {
        position: absolute;
        right: 0;
        top: calc(50% + 2px);
        max-width: 1412px;
        width: 80vw;
        height: 1px;
        background-color: rgba(178,166,156,.2);
        display: flex;
        @include media-breakpoint-down(lg) {
            width: 70vw;
        }
    }
}
.swiper {
    max-height: 100%;
    padding-right: 33%;
    flex: 0 0 100%;
    .swiper-slide {
        height: auto;
    }
}
</style>
